$pink: rgb(255, 180, 230);
$blue: rgb(158, 232, 255);

$archivo: "Archivo", helvetica, sans-serif;

// Responsive common widths
// Must match widths defined in screenWidths.ts

// Breakpoint XS (375px)
$min-width-xs: 375px;
$max-width-xs: 767px;

// Breakpoint SM (768px)
$min-width-sm: 768px;
$max-width-sm: 991px;

// Breakpoint MD (992px)
$min-width-md: 992px;
$max-width-md: 1199px;

// Breakpoint LG (1200px)
$min-width-lg: 1200px;
$max-width-lg: 1599px;

// Breakpoint XL (1600px)
$min-width-xl: 1600px;

$breakpoints: (
    xs: $min-width-xs,
    sm: $min-width-sm,
    md: $min-width-md,
    lg: $min-width-lg,
    xl: $min-width-xl
);

@import url('https://fonts.googleapis.com/css?family=Archivo:400,500,600,800');

@mixin viewport($breakpoint, $options: cascade) {
    @if map-has-key($breakpoints, $breakpoint) {
        $value: map-get($breakpoints, $breakpoint);

        @media screen and (min-width: $value) {
            @content;
        }

        @if ($options == no-cascade) {
            @if $breakpoint == xs {
                @media (min-width: $min-width-xs) and (max-width: $max-width-xs) {
                    @content;
                }
            } @else if $breakpoint == sm {
                @media (min-width: $min-width-sm) and (max-width: $max-width-sm) {
                    @content;
                }
            } @else if $breakpoint == md {
                @media (min-width: $min-width-md) and (max-width: $max-width-md) {
                    @content;
                }
            } @else if $breakpoint == lg {
                @media (min-width: $min-width-lg) and (max-width: $max-width-lg) {
                    @content;
                }
            }
        }
    }

    @if $breakpoint == landscape or $breakpoint == portrait {
        @media only screen and (orientation: #{$breakpoint}) {
            @content;
        }
    }

    @if $breakpoint == retina {
        @media only screen and (min-resolution: 2dppx) {
            @content;
        }
    }
}

html, body {
    height: 100%;
}

.app {

    @include viewport("md") {
        height: 100vh;
        display: grid;
        grid-template-columns: repeat(16, [col-start] 1fr);
        grid-column-gap: 16px;
    }

    &__header {
        display: flex;
        margin: 20px;
        justify-content: space-between;

        @include viewport("md") {
            grid-column: col-start / span 2;
            margin: 40px 0 0 40px;
            flex-direction: column;
            justify-content: flex-start;
        }

    }

    &__title {
        font-family: $archivo;
        font-weight: 800;
        font-size: large;
        background: linear-gradient($blue, $pink);
        background-clip: text;
        -webkit-text-fill-color: transparent;

        @include viewport("md") {
            margin: 0 0 60px 0;
            display: inline-block;
            min-width: 100px;
        }

    }

    &__navigation, &__daynight-navigation {

        ul {
            li {
                display: inline-block;
                margin: 0 15px 10px 0;
                padding: 0;
                font-family: $archivo;
                font-weight: 600;
                font-size: 12px;

                &:last-child {
                    margin-right: 0;
                }

                @include viewport("md") {
                    display: block;
                }

                a {
                    text-decoration: none;
                    display: block;
                    color: black;

                    &:visited {
                        color: inherit;
                    }

                    &:hover, &:focus, &:active {
                        color: $pink;
                        outline: none;
                    }

                    &.active {
                        &:first-letter {
                            color: $pink;
                        }

                        &:before {
                            visibility: inherit;
                        }
                    }
                }

            }

        }
    }

    &__daynight-navigation {

        @include viewport("md") {
            position: absolute;
            top: 50vh;
        }

        ul li {
            padding: 0 5px 0 0;
            margin: 0 5px 0 0;
            border-right: 2px solid $pink;
            cursor: pointer;

            @include viewport("md") {
                display: inline-block;
            }

            &:last-child {
                border: none;
            }

            &.active, &:hover {
                color: $blue;
            }
        }
    }

    &__photo-navigation {
        display: none;

        @include viewport("md") {
            display: inherit;
            margin: auto 0 40px 0;
            min-width: 100px;
        }

        ul {

            li {
                font-family: $archivo;
                font-weight: 600;
                text-decoration: none;
                font-size: 12px;
                color: black;
                display: inline-block;
                padding: 0 5px 0 0;
                margin: 0 5px 0 0;
                border-right: 2px solid $pink;

                &:last-child {
                    border: none;
                }

                &:hover, &:focus {
                    color: $blue;
                    outline: none;
                }

            }
        }
    }

    &__body {
        display: flex;
        justify-content: center;
        align-items: center;

        @include viewport("md") {
            grid-column: 4 / span 12;
            position: relative;
        }

        img {
            max-width: calc(100% - 40px);
            max-height: 100vh;
        }

        &--mobile-photos {
            flex-direction: column;

            img {
                margin: 0 0 20px 0;
            }
        }

        &--about {
            margin: 0 20px;
            flex-direction: column;
            justify-content: left;
            align-items: normal;
            text-align: left;

            @include viewport("md") {
                grid-column: 4 / span 10;
                margin: 40px 0;
            }

            img {
                max-width: 100%;
                margin: 0 0 20px 0;
            }

            p {
                font-family: $archivo;
                margin: 0 0 10px 0;
                font-size: 12px;
                line-height: 18px;

                em, a {
                    color: $blue;
                    text-decoration: none;
                }

                em {
                    font-weight: 600;
                    font-style: italic;
                }

                strong {
                    color: $pink;
                    font-weight: 600;
                }
            }

            h2 {
                font-family: $archivo;
                font-weight: 600;
                margin: 15px 0 10px 0;
                font-size: 14px;
            }
        }

    }
}